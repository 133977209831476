<template>
	<div class="app-body">
		<div class="login-header">系统登录</div>
		<div class="login-form">
			<div class="cell-group">
				<div class="cell-value">
					<input type="text" placeholder="请输入用户名" v-model="loginForm.username" class="form-input">
				</div>
			</div>
			<div class="cell-group">
				<div class="cell-value">
					<input :type="closeFlag ? 'text' : 'password'" placeholder="请输入密码" v-model="loginForm.password" class="form-input">
				</div>
				<div class="cell-button" @click="showPassword">
					<i class="van-icon" :class="closeFlag ? 'van-icon-eye-o' : 'van-icon-closed-eye'" ></i> 
				</div>
			</div>
			<div class="cell-form-submit">
				<button class="btn btn-blue btn-block btn-lg" @click.prevent="handleLogin">立即登录</button>
			</div>
		</div>
	</div>
</template>
<script>
import { Toast } from 'vant'
import { mapActions } from 'vuex'
export default{
	name:'login',
	components: {
		
	},
	data() {
		return {
			flag: false,//验证码按钮
			closeFlag: false, //密码显示
			loginForm: {'username':'','password':''}
		}
	},
	mounted() {

	},
	methods: {
		showPassword() {
            this.closeFlag = !this.closeFlag
        },
		handleLogin() {
			var validateFlag = this.isValidateForm()
			if(validateFlag == true)
			{
				this.login(this.loginForm).then(res => {
					if(res.error_code == 1000){
						Toast({message:res.msg,duration:1000})
						setTimeout(() => { 
							this.$router.push({ path: "/Home" })
						},1200)
					}else{
						Toast({message:res.msg,duration:1000})	
					}
				}).catch(error=>{
					console.log(error.message)
				})
			}else{
				Toast(validateFlag)
			}
		},
		isValidateForm() {
			return true
        },
        ...mapActions(['login'])
	}
}
</script>
<style lang="scss" scoped>
	.app-body{
		background:url(../assets/images/bottom.png) no-repeat bottom #fff;
		background-size:100%;
		.login-header{
			text-align:center;
			padding:1rem;
			font-size:0.6rem;
		}
		.login-form{
			margin:0 1rem;
			.cell-form-submit{
				margin-top:0.8rem;
				margin-bottom:0.2rem;
			}
		}
		.footer-bg{
			position:fixed;
			bottom:0rem;
		}
	}
</style>